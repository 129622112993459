<template>
 <v-overlay :value="overlay" opacity="0.8" absolute>
     <div class="text-center">
            <v-avatar size="50">
            <v-img :src="require('@/assets/Spinner-1s-200px.svg')"/>
            </v-avatar>
            </div>
            <div class="text-center text-title-1 natural--text">กรุณารอสักครู่</div>
            <div class="text-center text-title-1 natural--text">กำลังประมวลผล.....</div>
        
    </v-overlay>
</template>

<script>
export default {
  props: {
    overlay: {
      type: Boolean,
      default: false
    },
  },

}
</script>
